// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS & Lodash
import { get, trim } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil, catchError, take } from 'rxjs/operators';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/reducers';
// Auth
import { Login } from '../../../core/auth';
import { GlobalErrorHandler, SentryService } from '@core/utils';
import { AuthService } from '@auth-service';
// Model
import { IUserLogin } from 'src/app/core/auth/_models/user.model';
import { ISentryUserIdentity } from '@shared/models';
// Material
import { MatSnackBar } from '@angular/material/snack-bar';
// Transloco
import { TranslocoService } from '@jsverse/transloco';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {

	// Public params
	loginForm: FormGroup;
	loading = false;
	errors: any = [];
	companies = [];

	// Private declarations
	private unsubscribe = new Subject<void>();
	private returnUrl: any;

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr: change detection
	 * @param route: router route
	 * @param globalErrorHandler: GlobalErrorHandler
	 * @param sentryService: SentryService
	 * @param snackBar: MatSnackBar
	 * @param {TranslocoService} translocoServic
	 */
	constructor(
		readonly router: Router,
		readonly auth: AuthService,
		readonly store: Store<AppState>,
		readonly fb: FormBuilder,
		readonly cdr: ChangeDetectorRef,
		readonly route: ActivatedRoute,
		readonly globalErrorHandler: GlobalErrorHandler,
		readonly sentryService: SentryService,
		private readonly snackBar: MatSnackBar,
		private readonly translocoService: TranslocoService,
	) {
	}

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.initLoginForm();

		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params.returnUrl || '/';
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		this.loading = true;
		const username = trim(controls.email.value).toLowerCase();
		const loginData: IUserLogin = {
			username,
			password: controls.password.value,
			company_id: controls.companyid.value,
			company_default: null
		};
		this.auth.login(loginData).pipe(
			take(1),
			catchError(error => this.globalErrorHandler.handleError(`login-form-submit | ${loginData.username}`, error)),
			takeUntil(this.unsubscribe)
		).subscribe((response) => this.handleLoginResponse(response));
	}

	/**
	 * Checking control validation
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	private initLoginForm() {
		this.loginForm = this.fb.group({
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320)
			])
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			companyid: [null]
		});
	}

	/**
	 * Handle Login Response
	 * @param response: any
	 * @returns void
	 */
	private handleLoginResponse(response: any): void {
		const checkIsCompany = get(response, 'data.company');
		if (response && response.success) {
			if (null === checkIsCompany) {
				this.snackBar.open(this.translocoService.translate('sentence_company_account_not_found'), this.translocoService.translate('label_close'), {
					panelClass: ['danger-dialog']
				});
				this.loading = false;
			} else {
				this.store.dispatch(new Login({ user: response?.data, authToken: response?.data?.token?.legacy }));
				this.router.navigateByUrl(this.returnUrl); // Main page
			}
		} else {
			this.snackBar.open(this.translocoService.translate('sentence_the_login_details_is_incorrect'), this.translocoService.translate('label_close'), {
				panelClass: ['danger-dialog']
			});
			this.loading = false;
		}
		this.cdr.markForCheck();
	}
}
