// logo.service.ts
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LogoService {

    /**
     * Get the logo source based on the domain.
     * @returns {string} The logo source URL.
     */
    public getLogoSrc(): string {
        const hostname = window.location.hostname;
        const subdomain = this.getSubdomain(hostname);
        return `assets/media/logos/${subdomain}/logo.png`;
    }

    /**
     * Get Subdomain
     * @private
     * @param {string} hostname
     * @returns {(string)}
     */
    private getSubdomain(hostname: string): string {
        const parts = hostname.split('.');

        // Check if the domain has at least 3 parts to avoid errors
        if (parts.length >= 2) {
            // Return the second-to-last part, which is the main domain
            return parts[parts.length - 2];
        }

        // Fallback to 'bildelsbasen' if something goes wrong
        return 'bildelsbasen';
    }
}