<div class="d-flex flex-column flex-lg-row flex-column-fluid">

	<!--begin::Aside-->
	<div class="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-1 auth-bg">
		<!--begin::Content-->
		<div class="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">

			<!--begin::Title-->
			<h1 class="text-white fs-2qx fw-bolder text-center mb-7">
				Welcome to Prime!
			</h1>
			<!--end::Title-->

			<!--begin::Text-->
			<div class="text-white fs-base text-center">
				Dismantler backend sales system for the next generation.
			</div>
			<!--end::Text-->
		</div>
		<!--end::Content-->
	</div>
	<!--end::Aside-->

	<!--begin::Body-->
	<div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 order-lg-2">
		<!--begin::Form-->
		<div class="d-flex flex-center flex-column flex-lg-row-fluid">
			<!-- begin:: Logo -->
			<div class="mb-12">
				<img [src]="logoSrc" class="h-40px" />
			</div>
			<!-- end:: Logo -->

			<!--begin::Wrapper-->
			<div class="p-10">
				<router-outlet></router-outlet>
			</div>
			<!--end::Wrapper-->
		</div>
		<!--end::Form-->

		<!--begin::Footer-->
		<div class="d-flex flex-end flex-wrap px-5">
			<div class="text-muted">
				{{today | date:'yyyy'}} &copy; Prime Version {{appVersion}}
			</div>
		</div>
		<!--end::Footer-->
	</div>
	<!--end::Body-->

</div>