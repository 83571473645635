<!--begin::Body-->
<div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-30">
  <!--begin::Forgot Password-->
  <div class="row">
    <div class="text-center mb-10 mb-lg-20">
      <h1 transloco="label_forgotten_password"></h1>
      <h6 class="text-muted fw-semibold" transloco="sentence_enter_email_to_reset_password"></h6>
    </div>

    <!-- begin:: Forgot Password Form -->
    <form class="form" [formGroup]="forgotPasswordForm" autocomplete="off">
      <!-- begin:: Email-->
      <div class="form-group">
        <mat-form-field class="mat-form-field-fluid">
          <mat-label transloco="label_email"></mat-label>
          <input matInput type="email" placeholder="{{'label_email' | transloco}}" formControlName="email" />
          @if (isControlHasError('email','required')) {
            <mat-error>
              <strong transloco="label_required_field"></strong>
            </mat-error>
          }
          @if (isControlHasError('email','email')) {
            <mat-error>
              <strong transloco="sentence_field_is_not_valid"></strong>
            </mat-error>
          }
          @if (isControlHasError('email','minLength')) {
            <mat-error>
              <strong transloco="dynamic_minimum_characters_hint" [translocoParams]="{ value: '3' }"></strong>
            </mat-error>
          }
          @if (isControlHasError('email','maxLength')) {
            <mat-error>
              <strong transloco="dynamic_maximum_characters_hint"
              [translocoParams]="{ value: '320' }"></strong>
            </mat-error>
          }
        </mat-form-field>
      </div>
      <!-- end:: Email-->

      <!--begin::Action-->
      <div class="form-group mt-3 d-flex flex-wrap justify-content-between">
        <a href="javascript:;" routerLink="/auth/login" id="kt_login_signup_cancel"
        class="btn btn-light-primary px-9 py-4 my-3 mx-4" transloco="label_back"></a>

        <button [disabled]="loading" (click)="submit()" id="kt_login_forgot_submit"
          class="btn btn-primary px-9 py-4 my-3 mx-4">
          <span transloco="label_submit"></span>
          @if (loading) {
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
          }
        </button>

      </div>
      <!--end::Action-->
    </form>
    <!-- end:: Forgot Password Form -->

  </div>
  <!--end::Forgot Password-->
</div>