<div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-30">
  <!--begin::Signin-->
  <div class="row">
    <div class="text-center mb-10 mb-lg-20">
      <h1 transloco="label_sign_in"></h1>
      <h6 class="text-muted fw-semibold" transloco="sentence_enter_your_username_and_password"></h6>
    </div>

    <!--begin::Form-->
    <form class="form" [formGroup]="loginForm" autocomplete="off" novalidate="novalidate" id="kt_login_form">
      <div class="form-group mb-5">
        <mat-form-field class="mat-form-field-fluid">
          <mat-label transloco="label_email"></mat-label>
          <input matInput type="email" placeholder="{{'label_email' | transloco}}" formControlName="email"
            autocomplete="off" />
          @if (isControlHasError('email', 'required')) {
            <mat-error>
              <strong transloco="label_required_field"></strong>
            </mat-error>
          }
          @if (isControlHasError('email', 'email')) {
            <mat-error>
              <strong transloco="sentence_field_is_not_valid"></strong>
            </mat-error>
          }
          @if (isControlHasError('email', 'minlength')) {
            <mat-error>
              <strong transloco="dynamic_minimum_characters_hint" [translocoParams]="{ value: '3' }"></strong>
            </mat-error>
          }
          @if (isControlHasError('email', 'maxlength')) {
            <mat-error>
              <strong transloco="dynamic_maximum_characters_hint" [translocoParams]="{ value: '320' }"></strong>
            </mat-error>
          }
        </mat-form-field>
      </div>

      <div class="form-group mb-5">
        <mat-form-field class="mat-form-field-fluid">
          <mat-label transloco="label_password"></mat-label>
          <input matInput type="password" placeholder="{{'label_password' | transloco}}" formControlName="password"
            autocomplete="off" />
          @if (isControlHasError('password', 'required')) {
            <mat-error>
              <strong transloco="label_required_field"></strong>
            </mat-error>
          }
          @if (isControlHasError('password', 'minlength')) {
            <mat-error>
              <strong transloco="dynamic_minimum_characters_hint" [translocoParams]="{ value: '3' }"></strong>
            </mat-error>
          }
          @if (isControlHasError('password', 'maxlength')) {
            <mat-error>
              <strong transloco="dynamic_maximum_characters_hint" [translocoParams]="{ value: '100' }"></strong>
            </mat-error>
          }
        </mat-form-field>
      </div>
      <!--begin::Action-->
      <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
        <a href="javascript:;" routerLink="/auth/forgot-password" class="text-dark-50 text-hover-primary my-3 me-2"
        id="kt_login_forgot" transloco="label_forgot_password"></a>
        <button [disabled]="loading" id="kt_login_signin_submit" (click)="submit()"
          class="btn btn-primary px-9 py-4 my-3">
          <ng-container>
            <span class="indicator-label" transloco="label_sign_in"></span>
            @if (loading) {
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            }
          </ng-container>

        </button>
      </div>
      <!--end::Action-->
    </form>
    <!--end::Form-->
  </div>
  <!--end::Signin-->
</div>